.MuiInputLabel-shrink {
  color: rgba(52, 49, 76, 0.54);
  font-size: 17px;
}
.MuiOutlinedInput-notchedOutline > legend {
  font-size: 13.5px;
}
.MuiInputLabel-shrink {
  color: rgb(5 1 38 / 69%);
  font-weight: 600;
  font-size: 17px;
}
* {
  -webkit-tap-highlight-color: transparent;
}
.css-1gnzo41-MuiFormLabel-root-MuiInputLabel-root {
  color: rgb(52 49 76 / 62%);
}
a {
  color: #3b3ea1;
}
.profile {
  padding-bottom: 30px;
  width: 100%;
  margin: 0 auto;
  border-radius: 10px;
}

.profile-banner {
  height: 30vh;
  width: 100%;
  background: linear-gradient(
    -90deg,
    #7b99ff 0%,
    #7796ff 10%,
    #8ea7ff 25%,
    #a7b8f4 45%,
    #a7b8f4 60%,
    #8ea7ff 75%,
    #7796ff 85%,
    #7b99ff 100%
  );
  background-size: 100%;
  border-radius: 10px;
}

.profile-picture {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  position: relative;
  margin: 0 auto;
  top: -90px;
  text-align: center;
  border: 10px solid #fff;
}

.profile-info {
  text-align: center;
  margin-top: -85px;
}
.profile-info span {
  font-size: 1.7rem;
}

.profile-info small {
  font-size: 1.1rem;
  color: #8c8c8c;
}

.profile-picture img {
  border-radius: 50%;
  height: 100%;
  width: 100%;
  transform: scale(1.15);
  margin-top: -3px;
}

:root {
  --main-color: #1877f2;
  --text-color: #050505;
  --full-color: 255 255 255;
  --empty-color: 0 0 0;
  --abalance1: rgba(var(--full-color) / 1);
  --abalance80: rgba(var(--full-color) / 0.8);
  --balance1: rgb(var(--empty-color) / 0.05);
  --balance2: rgb(var(--empty-color) / 0.1);
  --balance3: rgb(var(--empty-color) / 0.15);
  --balance4: rgb(var(--empty-color) / 0.2);
  --balance8: rgb(var(--empty-color) / 0.6);
  --balance10: rgb(var(--empty-color) / 0.8);
  --balance-full: #f0f2f5;
  --height-header: 40px;
  --size1: 4px;
  --size2: calc(var(--size1) * 2);
  --size3: calc(var(--size1) * 3);
  --size4: calc(var(--size1) * 4);
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
.text {
  font-size: 14px;
}
.common-post + * {
  background-color: var(--balance-full);
}
.common-post {
  padding: var(--size3) var(--size4);
  padding-block-end: var(--size1);
  margin-block-end: var(--size4);
  background-color: var(--abalance1);
  border-radius: var(--size2);
  box-shadow: 0 1px 2px var(--balance4);
  width: 515px;
  max-width: 100%;
}
.common-post-header {
  margin-bottom: var(--size2);
}
.u-flex {
  display: flex !important;
}
.user-image {
  border-radius: 50%;
  margin-inline-end: var(--size2);
}
.user-and-group {
  font-weight: bold;
  font-size: 15px;
}
.time-and-privacy {
  font-size: 12.5px;
  letter-spacing: 0.7px;
}
.icon-button-2 {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  text-align: center;
}
.icon-menu {
  text-align: center;
}
button {
  font-family: inherit;
  font-size: inherit;
  background-color: transparent;
  color: inherit;
  border-width: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  text-align: inherit;
}
.icon-button-2 .icon-menu::before {
  vertical-align: 3px;
}
.icon-menu::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: var(--balance10);
  box-shadow: -6px 0px var(--balance10), 6px 0px var(--balance10);
}
.u-margin-inline-start {
  margin-inline-start: auto !important;
}
.common-content {
  word-break: break-word;
  text-align: justify;
}
.common-content p {
  margin: var(--size1) 0;
  white-space: pre-wrap;
}
.common-content b {
  font-weight: bold;
  white-space: pre-wrap;
}
.common-content a:not(.embed-content) {
  color: var(--main-color);
}
.reactions {
  letter-spacing: -2px;
  display: inline-flex;
  align-items: center;
}
.reactions-total {
  padding-left: 5px;
}
.summary > * + * {
  margin-inline-start: var(--size1);
}
.actions-buttons {
  padding-top: var(--size1);
  margin-top: var(--size2);
  border-top: solid 1px var(--balance4);
}
.actions-buttons-list {
  list-style: none;
  padding-left: 0;
  margin: 8px auto;
}
.actions-buttons-item {
  flex: 1 1;
}
.actions-buttons-button {
  width: 100%;
  text-align: center;
  height: 32px;
  border-radius: var(--size1);
  display: flex !important;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.actions-buttons-button .icon {
  margin-inline-end: var(--size1);
  filter: grayscale(1);
}
.embed-content {
  display: block;
  background-color: var(--balance1);
  margin: 16px -16px;
}
.embed-content-image {
  width: 100%;
}
.embed-content-text {
  padding: var(--size3) var(--size4);
  background-color: #f7f7f7;
}
.embed-content-info {
  text-transform: uppercase;
  color: var(--balance8);
  font-size: 1.1rem;
  margin-bottom: var(--size1);
}
.embed-content-title {
  font-weight: bold;
  margin: auto;
  font-size: 1.1rem;
}
.like-button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.like-button.animated {
  -webkit-animation: pop 0.9s both;
  animation: pop 0.9s both;
}
.like-button .like-icon {
  opacity: 1;
}
.like-button.active .like-icon {
  color: #2c469e;
}
.like-text.active {
  color: #2c469e;
}
.reactions span {
  color: #2c469e;
}
.see-more-link {
  cursor: pointer;
  color: black;
  font-weight: bold;
}
.see-more-link:hover {
  text-decoration: underline;
}

@-webkit-keyframes pop {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pop {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.time-and-privacy > *:not(:first-child)::before {
  content: "";
  vertical-align: bottom;
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 10px;
  background-color: var(--balance10);
  margin: var(--size2);
}
